import styled from 'styled-components';

import { Container } from 'components';

export const Wrapper = styled.div`
  min-height: 100vh;
  background-image: url('images/headerBackground.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: black;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledContainer = styled(Container)`
  height: 100%;
  flex-grow: 1;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const PoweredBy = styled.div`
  margin-top: 2.75rem;
  margin-bottom: 1.25rem;

  text-align: center;
`;
