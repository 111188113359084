import { signOut } from 'next-auth/client';
import Image from 'next/image';
import { ReactNode } from 'react';

import {
  GOLD_SPONSORS,
  ORGANIZING_PARTNERS,
  PARTNERS,
  SILVER_PLUS_SPONSORS,
  SILVER_SPONSORS
} from 'libs/constants';

import { About, Footer, Container, SponsorSection } from 'components';
import ExternalLink from 'components/ExternalLink';

import * as S from './styles';

const StageLayout = ({ children }: { children?: ReactNode }) => (
  <S.Wrapper>
    <Container>
      <S.SignOutContainer>
        <S.SignOut onClick={() => signOut()}>Sign out</S.SignOut>
      </S.SignOutContainer>
      <S.PoweredBy>
        Powered by{' '}
        <ExternalLink href="https://www.hacksoft.io/">
          <Image width={82} height={14.47} src="/images/hacksoftLogo.svg" />
        </ExternalLink>
      </S.PoweredBy>
      {children}
      <SponsorSection
        name="Organizing Partner"
        sponsors={ORGANIZING_PARTNERS}
      />
      <SponsorSection name="Gold Sponsors" sponsors={GOLD_SPONSORS} />
      <SponsorSection name="Silver+ Sponsors" sponsors={SILVER_PLUS_SPONSORS} />
      <SponsorSection name="Silver Sponsors" sponsors={SILVER_SPONSORS} />
      <SponsorSection name="Partners" sponsors={PARTNERS} />
      <About />
      <Footer />
    </Container>
  </S.Wrapper>
);

export default StageLayout;
