import Image from 'next/image';
import { useState } from 'react';

import * as S from './styles';

// TODO: Add actual prop type
const Talk = ({
  talk,
  hasSpeaker = true
}: {
  talk: any;
  hasSpeaker?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <S.Top
        isClickable={hasSpeaker}
        onClick={() => (hasSpeaker ? setIsOpen(!isOpen) : {})}>
        <S.Time>{talk.time}</S.Time>
        <S.Title>
          {hasSpeaker && <S.SpeakerName>{talk.speaker.name}: </S.SpeakerName>}
          {talk.title}
        </S.Title>
        {hasSpeaker && <S.Chevron flipped={isOpen} />}
      </S.Top>
      {isOpen && (
        <S.Bottom>
          <S.Avatar>
            <Image
              layout="fill"
              src={talk.speaker.avatar}
              objectFit="contain"
              objectPosition="center"
            />
          </S.Avatar>
          <S.Details>
            <S.SectionTitle>About</S.SectionTitle>
            <S.Paragraph>{talk.speaker.bio}</S.Paragraph>
            <S.SectionTitle>Talk</S.SectionTitle>
            <S.Paragraph>{talk.description}</S.Paragraph>
          </S.Details>
        </S.Bottom>
      )}
    </>
  );
};

export default Talk;
