import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;

  width: 100%;
  height: 100%;
  max-width: 330px;

  padding: 100px 15px;
`;

export const Title = styled.h1`
  text-align: center;
  color: ${({ theme }) => theme.orange};
  font-size: ${({ theme }) => theme.headingSize};
`;

export const Description = styled.p`
  text-align: center;
  margin-bottom: 30px;
`;

export const Error = styled.div`
  padding: 15px;
  margin-bottom: 30px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.backgroundOrange};

  background-color: ${({ theme }) =>
    theme.backgroundOrange}33; // The 33 is for 20% opacity

  animation: flip 0.3s linear;

  @keyframes flip {
    from {
      transform: rotateX(90deg);
    }

    to {
      transform: rotateX(0deg);
    }
  }
`;

export const Label = styled.label`
  width: 100%;
  text-align: left;
`;

export const Input = styled.input`
  width: 100%;
  padding: 5px;
  box-sizing: border-box;

  margin-top: 10px;

  color: white;

  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.backgroundOrange};

  &:focus {
    outline: none;
  }
`;

export const Button = styled.button`
  width: 100%;
  padding: 15px;
  margin-top: 30px;
  box-sizing: border-box;

  color: white;
  cursor: pointer;
  user-select: none;
  border: 1px solid ${({ theme }) => theme.backgroundOrange};

  background-image: linear-gradient(
    315deg,
    ${({ theme }) => theme.backgroundOrange} 0%,
    black 60%
  );
  background-size: 200% 100%;
  background-position: left;
  background-repeat: no-repeat;
  transition: 500ms;

  &:hover {
    background-position: right;
  }

  &:focus {
    outline: none;
  }

  &:not(:first-of-type) {
    margin-top: 10px;
  }

  ${({ submitting }: { submitting?: boolean }) =>
    submitting &&
    css`
      opacity: 0.6;
      cursor: not-allowed;
    `}
`;

export const LoadingText = styled(Title)`
  &:after {
    content: '';
    white-space: pre;
    animation: addDots 1s linear infinite;

    @keyframes addDots {
      0% {
        content: '';
      }

      25% {
        content: '.';
      }

      50% {
        content: '..';
      }

      75% {
        content: '...';
      }
    }
  }
`;
