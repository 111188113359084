import styled from 'styled-components';

import { SignInError } from 'components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-grow: 1;

  width: 100%;
  height: 100%;
  max-width: 330px;

  padding: 100px 15px;
`;

export const Title = styled.h1`
  color: ${({ theme }) => theme.orange};
  font-size: ${({ theme }) => theme.headingSize};
`;

export const Description = styled.p`
  text-align: center;
  margin-bottom: 30px;
`;

export const StyledSignInError = styled(SignInError)`
  margin-bottom: 30px;
`;

export const Label = styled.label`
  width: 100%;
  text-align: left;
`;

export const Input = styled.input`
  width: 100%;
  padding: 5px;
  box-sizing: border-box;

  margin-top: 10px;

  color: white;

  background-color: transparent;
  border: 1px solid ${({ theme }) => theme.backgroundOrange};

  &:focus {
    outline: none;
  }
`;

export const LostAccessCode = styled.a`
  cursor: pointer;
  font-size: 1rem;
  margin-top: 10px;
  align-self: flex-end;
  color: ${({ theme }) => theme.orange};

  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    display: inline-block;
    height: 0.5rem;
    width: 100%;
    border-bottom: 1px solid;
    margin-top: 10px;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: scale(0, 1);
    transform: scale(0, 1);
  }

  &:hover:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

export const Button = styled.button`
  width: 100%;
  padding: 15px;
  margin-top: 15px;
  box-sizing: border-box;

  color: white;
  cursor: pointer;
  user-select: none;
  border: 1px solid ${({ theme }) => theme.backgroundOrange};

  background-image: linear-gradient(
    315deg,
    ${({ theme }) => theme.backgroundOrange} 0%,
    black 60%
  );
  background-size: 200% 100%;
  background-position: left;
  background-repeat: no-repeat;
  transition: 500ms;

  &:hover {
    background-position: right;
  }

  &:focus {
    outline: none;
  }
`;

export const LoadingText = styled(Title)`
  &:after {
    content: '';
    white-space: pre;
    animation: addDots 1s linear infinite;

    @keyframes addDots {
      0% {
        content: '';
      }

      25% {
        content: '.';
      }

      50% {
        content: '..';
      }

      75% {
        content: '...';
      }
    }
  }
`;
