import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 10rem;
  margin-bottom: 10rem;
`;

export const Title = styled.h4`
  text-align: center;
  color: ${({ theme }) => theme.orange};
  font-size: ${({ theme }) => theme.headingSize};
`;

export const Content = styled.p`
  color: white;
  margin-top: 1rem;
  text-align: center;

  a {
    color: ${({ theme }) => theme.orange};
  }
`;
