import Image from 'next/image';

import * as S from './styles';

export interface ISponsor {
  url: string;
  img: string;
  isBoxWhite?: boolean;
}

const SponsorSection = ({
  name,
  sponsors
}: {
  name: string;
  sponsors: Array<ISponsor>;
}) => (
  <S.Container>
    <S.Title>{name}</S.Title>
    <S.SponsorGrid>
      {sponsors.map(({ isBoxWhite, url, img }: ISponsor) => (
        <S.SponsorBox key={url} href={url} white={isBoxWhite}>
          <Image
            src={img}
            height={76}
            width={256}
            objectFit="contain"
            objectPosition="center"
          />
        </S.SponsorBox>
      ))}
    </S.SponsorGrid>
  </S.Container>
);

export default SponsorSection;
