import _ from 'lodash';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';

import * as S from './styles';

const VideoPlayer = ({ url }: { url?: string }) => {
  const router = useRouter();

  const activePath = router.asPath;

  return (
    <S.Container>
      <S.StageSwitcher>
        {_.range(1, 4).map((index) => (
          <Link key={index} href={`/stage-${index}`}>
            <S.StageLink active={activePath.startsWith(`/stage-${index}`)}>
              Stage {index}
            </S.StageLink>
          </Link>
        ))}
      </S.StageSwitcher>
      <S.Player>
        <S.VimeoFrame
          src={url}
          frameBorder="0"
          allow="autoplay; fullscreen; picture-in-picture"
          allowFullScreen
        />
      </S.Player>
    </S.Container>
  );
};

export default VideoPlayer;
