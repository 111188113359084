import _ from 'lodash';
import { useRouter } from 'next/dist/client/router';
import { useState } from 'react';

import * as S from './styles';

interface IProps {
  error?: string;
  loading: boolean;
  submitting: boolean;
  hasBeenSubmitted: boolean;
  handleSubmit: ({ email }: { email: string }) => void;
}

const ResendAccessCodeForm = ({
  error,
  submitting,
  handleSubmit,
  loading,
  hasBeenSubmitted
}: IProps) => {
  const [email, setEmail] = useState('');

  const router = useRouter();
  const callbackUrl = _.get(router, 'query.callbackUrl', '/stage-1');

  return (
    <S.Container>
      {loading ? (
        <S.LoadingText>Loading</S.LoadingText>
      ) : (
        <>
          <S.Title>Resend access code</S.Title>
          <S.Description>
            Enter your registration email to continue.
          </S.Description>
          {error && <S.Error>{error}</S.Error>}
          {hasBeenSubmitted && (
            <S.Error>Email will be arriving shortly.</S.Error>
          )}
          <S.Label>Email</S.Label>
          <S.Input
            type="email"
            id="email"
            name="email"
            value={email}
            placeholder="email@address.com"
            onChange={(e) => setEmail(e.target.value)}
          />
          {hasBeenSubmitted ? (
            <S.Button
              type="button"
              onClick={() => router.push(`/signin?callbackUrl=${callbackUrl}`)}>
              Back to Sign in
            </S.Button>
          ) : (
            <S.Button
              type="button"
              submitting={submitting}
              onClick={submitting ? () => {} : () => handleSubmit({ email })}>
              {submitting ? 'Loading...' : 'Resend access code'}
            </S.Button>
          )}
        </>
      )}
    </S.Container>
  );
};

export default ResendAccessCodeForm;
