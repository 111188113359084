import * as S from './styles';

interface IProps {
  error: string;
  className?: string;
}

const ERRORS: { [key: string]: string } = {
  Signin: 'Try signing with a different account.',
  Callback: 'Try signing with a different account.',
  CredentialsSignin:
    'Sign in failed. Check the details you provided are correct.',
  default: 'Unable to sign in.'
};

const SignInError = ({ error, className }: IProps) => {
  const errorMessage = ERRORS[error] || ERRORS.default;

  return <S.Container className={className}>{errorMessage}</S.Container>;
};

export default SignInError;
