import styled from 'styled-components';

import type { ITheme } from 'libs/constants';

export const Container = styled.div`
  margin-bottom: 1.85rem;
`;

export const StageSwitcher = styled.div`
  display: flex;
  justify-content: flex-end;

  & > a:last-child {
    border-right: 1px solid ${({ theme }) => theme.backgroundOrange};
  }
`;

export const StageLink = styled.a`
  color: white;
  font-weight: ${({ active }: { active: boolean }) => (active ? 700 : 400)};

  height: 3rem;
  width: 7.5rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  border-style: solid;
  border-width: 1px 0 0 1px;
  border-color: ${({ theme }) => theme.backgroundOrange};

  background: ${({ theme, active }: { theme: ITheme; active: boolean }) =>
    active
      ? theme.backgroundOrange
      : `linear-gradient(315deg, ${theme.backgroundOrange} 0%, black 60%)`};

  background-size: 200% 100%;
  background-position: left;
  background-repeat: no-repeat;
  transition: 500ms;

  &:hover {
    font-weight: 700;
    background-position: right;
  }

  &:focus {
    outline: none;
  }
`;

export const Player = styled.div`
  padding-top: 56%;
  border: 1px solid ${({ theme }) => theme.backgroundOrange};

  position: relative;
`;

export const VimeoFrame = styled.iframe`
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
`;
