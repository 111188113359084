import type { IScheduleDataItem } from 'libs/constants';

import { Talk } from './components';
import * as S from './styles';

const StageSchedule = ({
  scheduleData
}: {
  scheduleData: IScheduleDataItem;
}) => (
  <S.Container>
    <S.StageIndicator>
      <S.IndicatorItem>Stage {scheduleData.number}</S.IndicatorItem>
      <S.IndicatorItem>
        {scheduleData.emoji} {scheduleData.name}
      </S.IndicatorItem>
    </S.StageIndicator>

    <S.Title>Schedule</S.Title>

    <S.Talks>
      <Talk
        hasSpeaker={false}
        talk={{ title: 'Opening', time: '13:30 - 14:00' }}
      />
      {scheduleData.talks.map((talk, index) => (
        <Talk talk={talk} key={index} />
      ))}
    </S.Talks>
  </S.Container>
);

export default StageSchedule;
