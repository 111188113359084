import styled, { css } from 'styled-components';

export const Top = styled.div`
  height: 2.5rem;
  border: 1px solid ${({ theme }) => theme.backgroundOrange};

  display: flex;
  align-items: center;

  padding-right: 1rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    height: auto;

    display: grid;
    grid-template-rows: 30px auto;
    grid-template-columns: 150px 1fr 15px;
    grid-template-areas: 'time . toggler' 'title title .';
  }

  ${({ isClickable }: { isClickable: boolean }) =>
    isClickable &&
    css`
      cursor: pointer;
      transition: box-shadow 0.3s linear;

      &:hover {
        box-shadow: 0px 0px 15px #ed8e00;
      }
    `}
`;

export const Time = styled.div`
  height: 100%;
  width: 10.5rem;
  margin-right: 0.25rem;
  background-color: ${({ theme }) => theme.backgroundOrange};

  color: black;
  font-weight: 700;
  font-size: 1.25rem;

  display: flex;
  align-items: center;
  justify-content: center;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    grid-area: time;
  }
`;

export const Title = styled.h3`
  font-size: 1.5rem;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    grid-area: title;
    padding: 1.3rem;
  }
`;

export const SpeakerName = styled.span`
  font-weight: 700;
  color: ${({ theme }) => theme.orange};
`;

export const Chevron = styled.div`
  width: 10px;
  height: 10px;

  margin-left: auto;

  border-style: solid;
  border-width: 0 0 3px 3px;
  border-color: ${({ theme }) => theme.orange};

  transition: all 0.2s linear;

  margin-top: ${({ flipped }: { flipped: boolean }) =>
    flipped ? '5px' : '-10px'};

  transform-origin: center;
  transform: rotate(-45deg)
    ${({ flipped }: { flipped: boolean }) => (flipped ? 'scale(-1)' : '')};

  @media ${({ theme }) => theme.breakpoints.tablet} {
    grid-area: toggler;
    margin-right: 1rem;
    margin-top: ${({ flipped }: { flipped: boolean }) =>
      flipped ? 'calc(1rem + 5px)' : 'calc(1rem - 10px)'};
  }
`;

export const Bottom = styled.div`
  display: grid;
  grid-gap: 0.5rem;
  align-items: flex-start;
  grid-template-columns: 10rem 1fr;

  animation: expand 0.3s ease;

  @media ${({ theme }) => theme.breakpoints.tablet} {
    display: flex;
    flex-direction: column;
  }

  @keyframes expand {
    0% {
      max-height: 0;
      overflow: hidden;
    }

    99% {
      max-height: 600px;
    }

    100%: {
      max-height: unset;
      overflow: unset;
    }
  }
`;

export const Avatar = styled.div`
  border: 1px solid ${({ theme }) => theme.backgroundOrange};

  width: 100%;
  padding-top: 100%;
  position: relative;

  background-size: cover;
  background-position: center;
  background-image: url('/images/loading.gif');

  @media ${({ theme }) => theme.breakpoints.tablet} {
    width: 10rem;
    min-height: 10rem;
    margin-bottom: 0.5rem;
    padding-top: unset;
  }
`;

export const Details = styled.div`
  padding: 1rem;
  box-sizing: border-box;

  white-space: pre-line;
  border: 1px solid ${({ theme }) => theme.backgroundOrange};
  background-color: ${({ theme }) =>
    theme.backgroundOrange}33; // The 33 is for 20% opacity
`;

export const SectionTitle = styled.h4`
  margin-bottom: 0.85rem;

  font-size: 1.85rem;
  color: ${({ theme }) => theme.orange};
`;

export const Paragraph = styled.p``;
