import _ from 'lodash';
import { useRouter } from 'next/dist/client/router';
import Link from 'next/link';
import { useState } from 'react';

import * as S from './styles';

interface IProps {
  error?: string;
  loading: boolean;
  handleSubmit: ({ barcode }: { barcode: string }) => void;
}

const SignInForm = ({ error, handleSubmit, loading }: IProps) => {
  const [barcode, setBarcode] = useState('');

  const router = useRouter();
  const callbackUrl = _.get(router, 'query.callbackUrl', '/stage-1');

  return (
    <S.Container>
      {loading ? (
        <S.LoadingText>Loading</S.LoadingText>
      ) : (
        <>
          <S.Title>Sign in</S.Title>
          <S.Description>Enter your access code to continue.</S.Description>
          {error && <S.StyledSignInError error={error} />}
          <S.Label>Access code</S.Label>
          <S.Input
            type="text"
            id="barcode"
            name="barcode"
            value={barcode}
            placeholder="XXXXXXXXXXXXXXXX"
            onChange={(e) => setBarcode(e.target.value)}
          />
          <Link href={`/resend-access-code?callbackUrl=${callbackUrl}`}>
            <S.LostAccessCode>Lost your access code?</S.LostAccessCode>
          </Link>
          <S.Button type="button" onClick={() => handleSubmit({ barcode })}>
            Sign in
          </S.Button>
        </>
      )}
    </S.Container>
  );
};

export default SignInForm;
