import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 1544px;

  @media ${({ theme }) => theme.breakpoints.desktop} {
    max-width: 100%;
    padding: 0 15px;
    box-sizing: border-box;
  }
`;
