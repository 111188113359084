import * as S from './styles';

const About = () => (
  <S.Container>
    <S.Title>About HackConf</S.Title>
    <S.Content>
      HackConf is Bulgaria's premier software development conference, organized
      "for developers, by developers," proudly organized by HackBulgaria.
      <br />
      Do you have any questions, or do you want to share your thoughts with us?
      We'll be happy to answer you; write us at{' '}
      <a href="mailto:team@hackconf.bg">team@hackconf.bg</a>.
    </S.Content>
  </S.Container>
);

export default About;
