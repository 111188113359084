import styled from 'styled-components';

export const Container = styled.div`
  padding: 15px;
  box-sizing: border-box;
  border: 1px solid ${({ theme }) => theme.backgroundOrange};

  background-color: ${({ theme }) =>
    theme.backgroundOrange}33; // The 33 is for 20% opacity

  animation: flip 0.3s linear;

  @keyframes flip {
    from {
      transform: rotateX(90deg);
    }

    to {
      transform: rotateX(0deg);
    }
  }
`;
