import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 100vh;
  background-image: url('images/headerBackground.png');
  background-size: 100% auto;
  background-repeat: no-repeat;
  background-color: black;

  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const SignOutContainer = styled.div`
  margin-top: 1rem;
  margin-bottom: 0.5rem;

  font-size: 1rem;
  text-decoration: underline;
  color: ${({ theme }) => theme.orange};

  display: flex;
  justify-content: flex-end;
`;

export const SignOut = styled.div`
  cursor: pointer;
`;

export const PoweredBy = styled.div`
  margin-top: 1.75rem;
  margin-bottom: 1.25rem;

  text-align: center;
`;
