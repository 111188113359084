import styled from 'styled-components';

import { ExternalLink } from 'components';

export const Container = styled.div`
  // Basically each which is not the first one.
  &:not(first-of-type) + &:not(first-of-type) {
    margin-top: 10rem;
  }
`;

export const Title = styled.h4`
  margin-bottom: 1.8rem;

  text-align: center;
  color: ${({ theme }) => theme.orange};
  font-size: ${({ theme }) => theme.headingSize};
`;

export const SponsorGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  margin-top: -2.5rem; // to negate the child item margin
  margin-left: -2.8rem; // to negate the child item margin

  & > * {
    margin-top: 2.5rem;
    margin-left: 2.8rem;
  }
`;

export const SponsorBox = styled(ExternalLink)`
  height: 110px;
  width: 290px;
  border: 1px solid ${({ theme }) => theme.backgroundOrange};

  transition: box-shadow 0.3s linear;

  padding: 1rem;
  box-sizing: border-box;

  background-color: ${({ white }: { white?: boolean }) =>
    white ? 'white' : 'black'};

  &:hover {
    box-shadow: 0px 0px 15px #ed8e00;
  }
`;
