import styled from 'styled-components';

export const Container = styled.div`
  margin-bottom: 7rem;
`;

export const StageIndicator = styled.div`
  margin-bottom: 1.75rem;

  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-rows: 48px;
  grid-template-columns: 121px 209px;

  font-weight: 700;
  font-size: 1.5rem;
`;

export const IndicatorItem = styled.div`
  height: 100%;
  border: 1px solid ${({ theme }) => theme.backgroundOrange};

  display: flex;
  align-items: center;
  justify-content: center;

  & + * {
    border-left: unset;
  }
`;

export const Title = styled.h4`
  margin-bottom: 1.75rem;

  text-align: center;
  color: ${({ theme }) => theme.orange};
  font-size: ${({ theme }) => theme.headingSize};
`;

export const Talks = styled.div`
  display: grid;
  grid-gap: 1.5rem;
`;
