import Image from 'next/image';

import * as S from './styles';

const Footer = () => (
  <S.Container>
    Thank you for joining HackConf 2021. We hope that you'll enjoy the
    conference!
    <S.OrganizedBy>
      Organizers of the conference<S.Divider>:</S.Divider>
      <S.StyledExternalLink href="https://hackbulgaria.com/python-101-forever">
        <Image src="/images/hackbulgariaLogo.png" height={16} width={111} />
      </S.StyledExternalLink>
    </S.OrganizedBy>
  </S.Container>
);

export default Footer;
