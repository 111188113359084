import Image from 'next/image';
import { ReactNode } from 'react';

import { Footer } from 'components';
import ExternalLink from 'components/ExternalLink';

import * as S from './styles';

const AuthLayout = ({ children }: { children?: ReactNode }) => (
  <S.Wrapper>
    <S.StyledContainer>
      <S.PoweredBy>
        Powered by{' '}
        <ExternalLink href="https://www.hacksoft.io/">
          <Image width={82} height={14.47} src="/images/hacksoftLogo.svg" />
        </ExternalLink>
      </S.PoweredBy>
      {children}
      <Footer />
    </S.StyledContainer>
  </S.Wrapper>
);

export default AuthLayout;
