import styled from 'styled-components';

import { ExternalLink } from 'components';

export const Container = styled.div`
  text-align: center;
  font-size: 1.25rem;

  padding-bottom: 5rem;
  box-sizing: border-box;
`;

export const OrganizedBy = styled.div`
  margin-top: 5.5rem;

  display: flex;
  justify-content: center;

  @media ${({ theme }) => theme.breakpoints.mobile} {
    flex-direction: column;
  }
`;

export const Divider = styled.span`
  margin-right: 5px;

  @media ${({ theme }) => theme.breakpoints.mobile} {
    visibility: hidden;
    margin-right: unset;
    margin-bottom: 10px;
  }
`;

export const StyledExternalLink = styled(ExternalLink)`
  margin-top: 3px;
`;
